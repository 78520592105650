* {
  font-family: Poppins, "sans-serif";
}

.white {
  color: white !important;
}

.container, .navbar {
  justify-content: center !important;
}

header {
  background-image: linear-gradient(rgba(52, 57, 51, 0.3), transparent);
}

.homeBackground {
  background: url("../public/images/laptopdisplayguitar.jpeg") no-repeat center center fixed;
  background-size: cover;
}

.home {
  width: 100%;
  height: 100vh;
}

.homeSignature {
  height: 40px;
}

.countdown {
  font-size: 70px;
  font-weight: 600;
}

.countdown--container {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 10%);
  color: #dfd1b9;
  background-image: linear-gradient(transparent, #343933);
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}/*# sourceMappingURL=custom.css.map */